<!-- 审核弹窗弹窗 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'订单审核':'订单审核'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="100px">

      <el-row :gutter="15" v-if="status == 0">
        <el-col :span="12">
          <div style="background: #36dd9e;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;">
            <img src="@/assets/images/home/hege.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
            <span style="font-size: 20px;font-weight: 500;color: #ffffff; vertical-align: middle;margin-left: 10px;">合格</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="background: #DADADA;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified(1)">
            <img src="@/assets/images/home/buhege.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
            <span style="font-size: 20px;font-weight: 500;color: #3F4155; vertical-align: middle;margin-left: 10px;">不合格</span>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="status == 0" style="margin-top: 20px;">
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>合同金额：</span>
            <span>300</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>合同补扣额：</span>
            <span>0</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div style="font-size: 16px;font-weight: 500;color: #4e4e4e;text-align: center;">
            <span>应付额：</span>
            <span>0</span>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="status == 0">
        <div style="background: #f7f8fa;border-radius: 6px;padding: 15px;margin-top: 20px;margin-bottom: 20px;">
          <el-row>
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="checked">NPS好评奖励</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="checked1">其他</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="checked1 == true">
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入其他原因"
                v-model="form.textarea">
            </el-input>
          </el-row>
        </div>
      </el-row>


      <el-row :gutter="15" v-if="status == 1">
        <el-col :span="12">
          <div style="background: #DADADA;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified(0)">
            <img src="@/assets/images/home/hegeactive.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
            <span style="font-size: 20px;font-weight: 500;color: #3F4155; vertical-align: middle;margin-left: 10px;">合格</span>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="background: #F54E71;border-radius: 6px;width: 140px;padding-top: 10px;padding-bottom: 10px;text-align: center;cursor: pointer;margin: auto;" @click="Unqualified()">
            <img src="@/assets/images/home/buhegeactive.png" alt="" style="width: 24px;height: 24px;vertical-align: middle;">
            <span style="font-size: 20px;font-weight: 500;color: #FFFFFF; vertical-align: middle;margin-left: 10px;">不合格</span>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="status == 1">
        <div style="background: #f7f8fa;border-radius: 6px;padding: 15px;margin-top: 20px;margin-bottom: 20px;">
          <el-row>
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="checked2">现场照片不合格</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="checked3">NPS好评奖励</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" style="margin-top: 7px;">
              <el-checkbox v-model="checked4">塔电照片不合格</el-checkbox>
            </el-col>
            <el-col :span="12">
              <el-form-item label="补扣款">
                <el-input v-model="form.name" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
          <el-col :span="12" style="margin-top: 7px;">
            <el-checkbox v-model="checked5">其他</el-checkbox>
          </el-col>
          <el-col :span="12">
            <el-form-item label="补扣款">
              <el-input v-model="form.name" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
      </el-row>
          <el-row v-if="checked5 == true">
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入其他原因"
                v-model="form.textarea">
            </el-input>
          </el-row>
        </div>
      </el-row>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      status:0,

      checked:true,
      checked1:false,
      checked2:true,
      checked3:false,
      checked4:false,
      checked5:false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    //选择类型
    activeItem(item,index){
      console.log(item);
      console.log(index);
      this.isShow = index;
    },

    //点击不合格
    Unqualified(number){
      this.status = number;
    },

  }
}
</script>

<style scoped lang="scss">

</style>
